import { logError } from '@core/logging';
export var PostmessageSentinel;
(function (PostmessageSentinel) {
    PostmessageSentinel["INTERNAL"] = "fwn-internal";
    PostmessageSentinel["EXTERNAL"] = "fwn";
})(PostmessageSentinel || (PostmessageSentinel = {}));
/**
 * Posts a message to the window and the parent window if in iframe.
 */
export function postMessageWrapper(message) {
    const windows = window.parent !== window ? [window.parent, window] : [window];
    windows.forEach((w) => {
        try {
            if (typeof w?.postMessage === 'function') {
                w.postMessage({ sentinel: PostmessageSentinel.EXTERNAL, ...message }, 
                // Setting `targetOrigin` to wildcard to allow postMessage
                // to reach any origin as we may want to support cross-origin PIP.
                // Wildcard also helps QA to test widgets with `file://widget.html`
                // local setups [CS-1361].
                '*');
            }
        }
        catch (err) {
            logError(err);
        }
    });
}
