import { CssValueTypeEnum } from './types';
import * as vars from './variables';
export const definitions = {
    [vars.BUTTON_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.BUTTON_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.BUTTON_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.BUTTON_BORDER]: {
        type: CssValueTypeEnum.BORDER,
    },
    [vars.FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.GRADIENT]: {
        type: CssValueTypeEnum.GRADIENT,
    },
    [vars.PRIMARY]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.ADD_TO_CALENDAR_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.ADD_TO_CALENDAR_BORDER]: {
        type: CssValueTypeEnum.BORDER,
    },
    [vars.ADD_TO_CALENDAR_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.ADD_TO_CALENDAR_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.ADD_TO_CALENDAR_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.ADD_TO_CALENDAR_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.ADD_TO_CALENDAR_HEIGHT]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.ADD_TO_CALENDAR_HOVER_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.ADD_TO_CALENDAR_HOVER_BORDER]: {
        type: CssValueTypeEnum.BORDER,
    },
    [vars.ADD_TO_CALENDAR_HOVER_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.ADD_TO_CALENDAR_HOVER_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.ADD_TO_CALENDAR_HOVER_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.ADD_TO_CALENDAR_WIDTH]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.THUMBNAIL_BORDER_RADIUS]: {
        type: CssValueTypeEnum.LENGTH_LIST,
    },
    [vars.THUMBNAIL_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.THUMBNAIL_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.THUMBNAIL_GAP]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.THUMBNAIL_HEIGHT]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.THUMBNAIL_WIDTH]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.PLAYER_WIDTH]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.PLAYER_MARGIN]: {
        type: CssValueTypeEnum.LENGTH_LIST,
    },
    [vars.PLAYER_HEIGHT]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.PLAYER_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_BORDER_RADIUS]: {
        type: CssValueTypeEnum.LENGTH_LIST,
    },
    [vars.PLAYER_CAPTION_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_CAPTION_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_CHAT_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_CHAT_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_CHAT_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.PLAYER_CHAT_TEXT_SHADOW]: {
        type: CssValueTypeEnum.SHADOW,
    },
    [vars.PLAYER_HEADER_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_HEADER_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.PLAYER_PINNED_BORDER_RADIUS]: {
        type: CssValueTypeEnum.LENGTH_LIST,
    },
    [vars.PLAYER_PINNED_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_PINNED_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.PLAYER_SHOP_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_SHOP_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PLAYER_SHOP_LIST_ITEM_HOVER_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.SHARE_BORDER]: {
        type: CssValueTypeEnum.BORDER,
    },
    [vars.SHARE_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.SHARE_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.SHARE_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.SHARE_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.SHARE_HEIGHT]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.SHARE_HOVER_BORDER]: {
        type: CssValueTypeEnum.BORDER,
    },
    [vars.SHARE_HOVER_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.SHARE_HOVER_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.SHARE_HOVER_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.SHARE_WIDTH]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.SHARE_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.SHARE_HOVER_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.STORYBLOCK_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.STORYBLOCK_BORDER_RADIUS]: {
        type: CssValueTypeEnum.LENGTH_LIST,
    },
    [vars.CHAT_REPLY_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.CHAT_REPLY_BORDER_RADIUS]: {
        type: CssValueTypeEnum.LENGTH_LIST,
    },
    [vars.CHAT_REPLY_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.CHAT_REPLY_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.CHAT_REPLY_TEXT_SHADOW]: {
        type: CssValueTypeEnum.SHADOW,
    },
    [vars.CHAT_REPLY_INITIAL_MSG_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.CHAT_REPLY_INITIAL_MSG_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.CHAT_REPLY_INITIAL_MSG_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.CHAT_PIN_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.CHAT_PIN_BORDER_RADIUS]: {
        type: CssValueTypeEnum.LENGTH_LIST,
    },
    [vars.PRODUCT_NAME_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PRODUCT_NAME_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.PRODUCT_NAME_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.PRODUCT_NAME_LETTER_SPACING]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.PRODUCT_NAME_TEXT_TRANSFORM]: {
        type: CssValueTypeEnum.TEXT_TRANSFORM,
    },
    [vars.PRODUCT_PRICE_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PRODUCT_PRICE_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.PRODUCT_DESCRIPTION_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.PRODUCT_DESCRIPTION_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.PRODUCT_IMAGE_BACKGROUND_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.LIVESTREAM_WING_HEIGHT]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.AD_BADGE_BACKGROUND]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.AD_BADGE_FONT_COLOR]: {
        type: CssValueTypeEnum.COLOR,
    },
    [vars.HERO_UNIT_COUNTDOWN_LETTER_SPACING]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_COUNTDOWN_TEXT_TRANSFORM]: {
        type: CssValueTypeEnum.TEXT_TRANSFORM,
    },
    [vars.HERO_UNIT_COUNTDOWN_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_COUNTDOWN_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.HERO_UNIT_COUNTDOWN_FLEX]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_TITLE_FONT_FAMILY]: {
        type: CssValueTypeEnum.FONT_STACK,
    },
    [vars.HERO_UNIT_TITLE_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_TITLE_FLEX]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_TITLE_LINE_HEIGHT]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_TITLE_TEXT_TRANSFORM]: {
        type: CssValueTypeEnum.TEXT_TRANSFORM,
    },
    [vars.HERO_UNIT_TITLE_LETTER_SPACING]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_TITLE_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.HERO_UNIT_CONTENT_TEXT_TRANSFORM]: {
        type: CssValueTypeEnum.TEXT_TRANSFORM,
    },
    [vars.HERO_UNIT_CONTENT_LETTER_SPACING]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_CONTENT_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.HERO_UNIT_CTA_TEXT_TRANSFORM]: {
        type: CssValueTypeEnum.TEXT_TRANSFORM,
    },
    [vars.HERO_UNIT_CTA_LETTER_SPACING]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_CTA_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.HERO_UNIT_CTA_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_START_TIME_FONT_SIZE]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_START_TIME_FONT_WEIGHT]: {
        type: CssValueTypeEnum.FONT_WEIGHT,
    },
    [vars.HERO_UNIT_START_TIME_LETTER_SPACING]: {
        type: CssValueTypeEnum.LENGTH,
    },
    [vars.HERO_UNIT_START_TIME_TEXT_TRANSFORM]: {
        type: CssValueTypeEnum.TEXT_TRANSFORM,
    },
    [vars.HERO_UNIT_START_TIME_LINE_HEIGHT]: {
        type: CssValueTypeEnum.LENGTH,
    },
};
