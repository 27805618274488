export var PlayerLayout;
(function (PlayerLayout) {
    PlayerLayout["MINIMIZED"] = "minimized";
    PlayerLayout["FULLSCREEN"] = "fullscreen";
    PlayerLayout["DEFAULT"] = "default";
})(PlayerLayout || (PlayerLayout = {}));
export var VideoPlayerOpenReason;
(function (VideoPlayerOpenReason) {
    VideoPlayerOpenReason["CLOSE_ME"] = "close_me";
    VideoPlayerOpenReason["STORYBLOCK_DOCK"] = "storyblock_dock";
    VideoPlayerOpenReason["STORYBLOCK_CONFIG"] = "storyblock_config";
    VideoPlayerOpenReason["PROGRAMMATIC"] = "programmatic";
    VideoPlayerOpenReason["INTERACTION"] = "interaction";
})(VideoPlayerOpenReason || (VideoPlayerOpenReason = {}));
export const openReasonPriority = {
    [VideoPlayerOpenReason.CLOSE_ME]: 1,
    [VideoPlayerOpenReason.STORYBLOCK_DOCK]: 2,
    [VideoPlayerOpenReason.STORYBLOCK_CONFIG]: 3,
    [VideoPlayerOpenReason.PROGRAMMATIC]: 4,
    [VideoPlayerOpenReason.INTERACTION]: 5,
};
