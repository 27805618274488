// Do not modify `.fireworkn.com` as it points to Alibaba CDN for China users.
const IGNORE_REGEXP = /\.fireworkn\.com/;
// Replace cdn prefix with a random CDN subdomain.
const CDN_SEARCH_REGEXP = /\/\/cdn\d+\./;
// CDN subdomains to replace the cdn prefix with.
const CDN_OPTIONS = ['cdn1', 'cdn3', 'cdn4'];
/**
 * Replace cdn prefix with a random CDN subdomain. Note: Hashing the url
 * ensures that the same url will always receive the same subdomain, otherwise
 * browsers will cancel unfinished requests.
 *
 * @param url
 * @returns
 */
export function roundRobinCDN(url, _optionsDI) {
    if (url && !IGNORE_REGEXP.test(url)) {
        // Simple hash function to consistently select a CDN
        let hash = 0;
        for (let i = 0; i < url.length; i++) {
            const char = url.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        const options = _optionsDI && _optionsDI.length > 0 ? _optionsDI : CDN_OPTIONS;
        const index = Math.abs(hash) % options.length;
        return url.replace(CDN_SEARCH_REGEXP, `//${options[index]}.`);
    }
    return url;
}
