import { customEvent, EventType } from '@core/customEvent';
import { shoppingApi } from './api';
/**
 *
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'shopping')) {
        Object.defineProperty(window._fwn, 'shopping', {
            value: shoppingApi,
            writable: false,
        });
    }
    customEvent({
        target: null,
        type: EventType.FW_SHOPPING_READY,
        data: {},
    });
}
