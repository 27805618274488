/**
 * @fileoverview
 * Creates HTMLVideoElement and caches it for later use. "Activated" video elements
 * are being created during user interaction frame (onClick) and are cached for later use
 * to allow programmatic un-muting and autoplay.
 */
import { lazyCacheGetter } from './lazyCacheGetter';
export const THIS_MANY_AT_ONCE = 10;
const createActivatedVideoElement = () => {
    const element = document.createElement('video');
    element.dataset['activated'] = 'true';
    element.muted = true;
    return element;
};
/**
 * Create a bunch of activated video elements and cache them for later use.
 * This function should be called during user interaction frame (onClick).
 */
export const createBunchOfActivatedVideoElements = () => {
    const cache = lazyCacheGetter();
    for (let i = 0; i < THIS_MANY_AT_ONCE; i++) {
        cache.push(createActivatedVideoElement());
    }
};
