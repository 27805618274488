/**
 * @fileoverview
 * Constants and helpers used by the Grid component.
 */
import { WidgetSizeEnum } from '@core/types';
export var ScrollDirectionEnum;
(function (ScrollDirectionEnum) {
    ScrollDirectionEnum["LEFT"] = "scroll-left";
    ScrollDirectionEnum["RIGHT"] = "scroll-right";
})(ScrollDirectionEnum || (ScrollDirectionEnum = {}));
/**
 * Calculate the number of columns for a given width
 * for a embed-feed's "grid" mode.
 */
export const columnsForWidth = (width) => {
    if (width >= 960) {
        return 5;
    }
    else if (width >= 640) {
        return 4;
    }
    else if (width >= 480) {
        return 3;
    }
    return 2;
};
const SM_MIN_NEW_CARD_WIDTH_BELOW_1024 = 346;
const SM_MIN_NEW_CARD_WIDTH_ABOVE_1024 = 346;
const MD_MIN_NEW_CARD_WIDTH_BELOW_1024 = 338;
const MD_MIN_NEW_CARD_WIDTH_ABOVE_1024 = 350;
const LG_MIN_NEW_CARD_WIDTH_BELOW_1024 = 480;
const LG_MIN_NEW_CARD_WIDTH_ABOVE_1024 = 492;
const getDynamicCarouselMinWidths = (size) => {
    switch (size) {
        case WidgetSizeEnum.MEDIUM:
            return {
                minWidthBelow1024: MD_MIN_NEW_CARD_WIDTH_BELOW_1024,
                minWidthAbove1024: MD_MIN_NEW_CARD_WIDTH_ABOVE_1024,
            };
        case WidgetSizeEnum.SMALL:
            return {
                minWidthBelow1024: SM_MIN_NEW_CARD_WIDTH_BELOW_1024,
                minWidthAbove1024: SM_MIN_NEW_CARD_WIDTH_ABOVE_1024,
            };
        default:
            return {
                minWidthBelow1024: LG_MIN_NEW_CARD_WIDTH_BELOW_1024,
                minWidthAbove1024: LG_MIN_NEW_CARD_WIDTH_ABOVE_1024,
            };
    }
};
/**
 * [CS-6201] Calculate number of columns based on number of videos for medium carousel V2
 * and decide if we're showing the new card style or not
 */
export const columnsByVideoCount = ({ count, containerWidth, size, }) => {
    const { minWidthAbove1024, minWidthBelow1024 } = getDynamicCarouselMinWidths(size);
    const cardWidth = containerWidth < 1024 ? minWidthBelow1024 : minWidthAbove1024;
    const showV2Card = (containerWidth - (count - 1) * 10) / count >= cardWidth;
    const columns = showV2Card ? count : columnsForWidth(containerWidth);
    return { columns, showV2Card };
};
