var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _Console_changeSubscribers, _Console_isOpenValue;
/* eslint-disable zeffo/no-window-storage */
import { DEBUG_KEY } from '@core/debug';
import { Z_DRAG_WRAPPER } from '@core/zindexes';
class Panel {
    constructor({ css }) {
        this.init = () => {
            this.element = document.createElement('div');
            this.element.style.cssText =
                `
      z-index: ${Z_DRAG_WRAPPER};
      background: rgb(0 0 0 / 80%);
      color: white;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 12px;
      letter-spacing: normal;
      line-height: 1.3;
      max-height: 100%;
      overflow-y: auto;
      padding: 0 0.5em;
      position: fixed;
      right: 0;
      text-transform: none;
      top: 0;
    ` + this.css;
            document.body.appendChild(this.element);
        };
        this.info = (...args) => this.write('lightblue', ...args);
        this.log = (...args) => this.write('lightgreen', ...args);
        this.warn = (...args) => this.write('yellow', ...args);
        this.error = (...args) => this.write('orangered', ...args);
        this.write = (color, entry, group = '', css = '', onClick = () => { }) => {
            const line = document.createElement('div');
            line.onclick = onClick;
            line.textContent = entry || '';
            line.style.cssText =
                `
      color: ${color};
    ` + css;
            if (group !== undefined) {
                if (!(group in this.groups)) {
                    const header = document.createElement('div');
                    header.textContent = `${group}`;
                    this.groups[group] = document.createElement('div');
                    this.groups[group].style.cssText = `
          border-bottom: dashed thin grey;
          padding: .5em 0;
        `;
                    this.groups[group].appendChild(header);
                    this.element?.appendChild(this.groups[group]);
                }
                this.groups[group].appendChild(line);
            }
            else {
                this.element?.appendChild(line);
            }
        };
        this.destroy = () => {
            this.groups = {};
            this.element?.remove();
        };
        this.css = css;
        this.element = null;
        this.groups = {};
    }
}
/**
 *
 */
export class Console {
    /**
     *
     */
    constructor() {
        this.panel = new Panel({
            css: `font-family: Monaco, Courier New, monospace; font-size: 10px; text-align: right;`,
        });
        this.queue = [];
        _Console_changeSubscribers.set(this, []);
        _Console_isOpenValue.set(this, false
        /**
         *
         */
        );
        this.openConsole = () => {
            this.panel.init();
            this.isOpen = true;
            this.renderQueue();
        };
        this.closeConsole = () => {
            this.panel.destroy();
            this.isOpen = false;
        };
        this.info = (...args) => this.pushLog('info', ...args);
        this.log = (...args) => this.pushLog('log', ...args);
        this.warn = (...args) => this.pushLog('warn', ...args);
        this.error = (...args) => this.pushLog('error', ...args);
        this.pushLog = (type, ...args) => {
            this.queue.push([type, ...args]);
            this.isOpen && this.renderLog(type)(...args);
        };
        this.renderLog = (type) => (...args) => {
            try {
                const { entry, group = '', css = '', onClick = () => { } } = args[0];
                const reducedEntry = this._reduceEntry(entry || args).replace(group, '');
                this.panel[type](reducedEntry, group, css, onClick);
            }
            catch (err) { }
        };
        this.renderQueue = () => {
            this.queue.forEach(([type, ...args]) => this.renderLog(type)(...args));
        };
        this.onStatusChanged = (cb) => {
            __classPrivateFieldGet(this, _Console_changeSubscribers, "f").push(cb);
            return () => {
                __classPrivateFieldSet(this, _Console_changeSubscribers, __classPrivateFieldGet(this, _Console_changeSubscribers, "f").filter((subscriber) => subscriber !== cb), "f");
            };
        };
        this._reduceEntry = (args) => typeof args.reduce === 'function'
            ? args.reduce((acc, curr) => {
                acc =
                    acc + ', ' + typeof curr === 'object' ? JSON.stringify(curr) : curr;
                return acc;
            }, '')
            : typeof args === 'object'
                ? JSON.stringify(args)
                : `${args}`;
        this.info({
            entry: 'FWN Console',
            group: '',
            css: 'font-weight:bold',
            onClick: () => {
                this.closeConsole();
            },
        });
    }
    /**
     *
     */
    get isOpen() {
        return __classPrivateFieldGet(this, _Console_isOpenValue, "f");
    }
    /**
     *
     */
    set isOpen(value) {
        __classPrivateFieldSet(this, _Console_isOpenValue, value, "f");
        __classPrivateFieldGet(this, _Console_changeSubscribers, "f").forEach((subscriber) => {
            subscriber(__classPrivateFieldGet(this, _Console_isOpenValue, "f"));
        });
    }
}
_Console_changeSubscribers = new WeakMap(), _Console_isOpenValue = new WeakMap();
/**
 *
 */
export function fwnConsole() {
    const instance = new Console();
    try {
        if (localStorage.getItem(DEBUG_KEY) && !instance.isOpen) {
            instance.openConsole();
        }
    }
    catch (err) { }
    const ConsoleToggler = () => {
        if (!instance.isOpen) {
            instance.openConsole();
            try {
                localStorage.setItem(DEBUG_KEY, 'true');
            }
            catch (err) { }
        }
        else {
            instance.closeConsole();
            try {
                localStorage.removeItem(DEBUG_KEY);
            }
            catch (err) { }
        }
        return instance.isOpen;
    };
    Object.defineProperty(ConsoleToggler, 'instance', {
        value: instance,
        writable: false,
    });
    return ConsoleToggler;
}
