/**
 * Cache for activated video elements for unmuted autoplay.
 * HTMLVideoElements are created during user interaction and
 * are cached for later use.
 */
export class VideoElementsCache {
    constructor() {
        this.cache = [];
        this.MAX_CACHE_SIZE = 20;
    }
    /**
     *
     */
    pop() {
        return this.cache.pop();
    }
    /**
     *
     */
    push(value) {
        this.cache = this.cache.slice(0, this.MAX_CACHE_SIZE);
        this.cache.push(value);
    }
    /**
     * Get cached items.
     */
    get items() {
        return this.cache;
    }
}
