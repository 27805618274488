/**
 * @fileoverview
 * Provides lazy access to the VideoElementCache stored
 * on _fwn web sdk object.
 */
import { getFWN } from '@core/fwn';
import { VideoElementsCache } from './VideoElementsCache';
/**
 *
 */
export const lazyCacheGetter = () => {
    const fwn = getFWN();
    if (fwn && !fwn?.videoElementsCache) {
        Object.defineProperty(fwn, 'videoElementsCache', {
            writable: false,
            value: new VideoElementsCache(),
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return fwn ? fwn?.videoElementsCache : new VideoElementsCache();
};
