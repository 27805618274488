/**
 * ATTENTION: read this if you are adding a new param:
 * 1. Add the param key to ALLOWED_KEYS. (or ALLOWED_PREFIXES if it can be
 *    partially matched)
 * 2. Add the param key to selected groups if it has special values:
 *    a. boolean => BOOL_VALUE_KEYS
 *    b. int => INT_VALUE_KEYS
 *    c. empty value allowed => EMPTY_VALUE_KEYS
 */
import { isFalsy } from '@core/helpers/boolean';
/** Keys that are allowed. */
const ALLOWED_KEYS = new Set([
    'a11y_disable_focus',
    'a11y_disable_previews',
    'a11y_limit_previews',
    'ad_positions',
    'ads_hide_badge',
    'activation_avatar_enabled',
    'activation_questions',
    'activation_thumbnail_urls',
    'activation_title',
    'allow_duplicate_ads',
    'api_host',
    'api_host_ads',
    'api_host_tracking',
    'api_host_tracking_legacy',
    'auto_apply_coupon_enabled',
    'autoplay',
    'autoplay_keep_thumbnail',
    'auto_embed_widget',
    'auto_subtitles_enabled',
    'branding',
    'captions',
    'carousel_subtitle',
    'channel',
    'ad_channel_id',
    'channel_id',
    'chat_channel_id',
    'collapsed',
    'cookies_days',
    'cookies_opt_in',
    'cookies_root',
    'cover',
    'dir',
    'dock',
    'email_embed',
    'expand_prod_desc_enabled',
    'full_height_pdp',
    'fullscreen_pdp_enabled',
    'handle_product_card_click',
    'handle_product_cta_click',
    'hashtag',
    'height',
    'hero_flip_order',
    'hero_hide_countdown',
    'hero_previous_event_promotion',
    'hero_text_mode',
    'hide_cart_quantity',
    'hide_cart_total',
    'hide_product_price',
    'hide_show_detail',
    'homepage_url',
    'horizontal',
    'in_sdk_webview',
    'key_moments',
    'label',
    'lang',
    'layout',
    'lead_generation',
    'light_mode_pdp',
    'live_label',
    'livestream_allow_username_change',
    'livestream_chat',
    'livestream_firstparty',
    'livestream_prompt_username_change',
    'livestream_username',
    'loop',
    'lsdesktop',
    'max_videos',
    'minimized',
    'mode',
    'moderator_icon',
    'muted',
    'name',
    'open_in',
    'overlay_play_button_size',
    'page_type',
    'page_url',
    'paginate_by',
    'per_page',
    'persist_cart',
    'pip',
    'pip_navigate',
    'pip_observe',
    'pip_outbound_target',
    'pip_target',
    'placement',
    'player_add_to_calendar',
    'player_captions',
    'player_close',
    'player_detached',
    'player_fullscreen',
    'player_fullscreen_contain',
    'player_key',
    'player_margin',
    'player_minimize',
    'player_more_menu',
    'player_placement',
    'player_play_pause',
    'player_tap_to_unmute',
    'player_tiles',
    'player_unmute',
    'playlist',
    'playlist_collection',
    'popup_position',
    'priority',
    'product_card',
    'product_checkout_card_v2',
    'product_click_use_instream_pdp',
    'product_image_carousel',
    'product_index',
    'product_tag_placement',
    'qr_code',
    'reset_transform',
    'reverse_audio_controls',
    'share',
    'share_label',
    'share_template',
    'shopping_cart_style',
    'shopping_title_disabled',
    'show_ads',
    'show_icon',
    'show_preroll',
    'showroom_id',
    'silent_mode',
    'size',
    'skus',
    'sold_and_fulfilled_enabled',
    'sp_outstream_tag',
    'start_time',
    'start_time_sku',
    'tap_to_watch',
    'target',
    'text',
    'theme',
    'thumbnail_cta',
    'thumbnail_product_card',
    'thumbnail_style',
    'title',
    'title_alignment',
    'title_size',
    'token',
    'type',
    'ui_asset_back',
    'ui_asset_close',
    'ui_asset_closed_captions_disabled',
    'ui_asset_closed_captions_enabled',
    'ui_asset_fullscreen',
    'ui_asset_menu',
    'ui_asset_minimize',
    'ui_asset_muted',
    'ui_asset_next',
    'ui_asset_pause',
    'ui_asset_play',
    'ui_asset_previous',
    'ui_asset_product_tag',
    'ui_asset_reaction',
    'ui_asset_reaction_animation',
    'ui_asset_shopping_bag',
    'ui_asset_shopping_cart',
    'ui_asset_unmuted',
    'ui_border_style',
    'ui_button_color',
    'ui_button_font_color',
    'ui_font_css',
    'ui_full_cta',
    'ui_player_style',
    'ui_primary_color',
    'ui_share_sheet',
    'upcoming_label',
    'user_id',
    'vast_attrs',
    'vertical',
    'video',
    'video_id',
    'wait_for_vast_attrs',
    'width',
    'vast_xml',
]);
/**
 * Keys with certain prefix are allowed.
 * @example
 * <fw-storyblock cohort_store="san mateo"></fw-storyblock>
 */
const ALLOWED_PREFIXES = ['cohort_'];
/**
 * Keys to be kept even if their value is empty.
 *
 * @example
 * <fw-storyblock title></fw-storyblock>
 */
const EMPTY_VALUE_KEYS = [
    'max_videos',
    'router',
    'show_preroll',
    'title', // title can be reset to empty string
    'ui_share_sheet',
];
/**
 * Keys representing boolean attributes. Their string values will be converted
 * to boolean.
 *
 * @example
 * <fw-storyblock autoplay></fw-storyblock>
 * `autoplay` value is set to true.
 */
const BOOL_VALUE_KEYS = new Set([
    'ads_hide_badge',
    'a11y_disable_focus',
    'a11y_limit_previews',
    'allow_duplicate_ads',
    'auto_apply_coupon_enabled',
    'auto_embed_widget',
    'auto_subtitles_enabled',
    'autoplay',
    'autoplay_keep_thumbnail',
    'branding',
    'captions',
    'cart_revamp',
    'cookies_opt_in',
    'cookies_root',
    'cover',
    'dock',
    'expand_prod_desc_enabled',
    'full_height_pdp',
    'fullscreen_pdp_enabled',
    'hero_flip_order',
    'hero_hide_countdown',
    'hero_previous_event_promotion',
    'hide_cart_quantity',
    'hide_cart_total',
    'hide_product_price',
    'hide_show_detail',
    'ic_coupon',
    'in_sdk_webview',
    'key_moments',
    'lead_generation',
    'light_mode_pdp',
    'livestream_allow_username_change',
    'livestream_chat',
    'livestream_firstparty',
    'loop',
    'lsdesktop',
    'minimized',
    'muted',
    'persist_cart',
    'pip',
    'pip_navigate',
    'pip_observe',
    'player_add_to_calendar',
    'player_captions',
    'player_close',
    'player_detached',
    'player_fullscreen',
    'player_fullscreen_contain',
    'player_minimize',
    'player_more_menu',
    'player_play_pause',
    'player_tap_to_unmute',
    'player_unmute',
    'player_tiles',
    'product_card',
    'product_checkout_card_v2',
    'product_click_use_instream_pdp',
    'product_image_carousel',
    'product_index',
    'reverse_audio_controls',
    'shopping_title_disabled',
    'show_ads',
    'show_preroll',
    'silent_mode',
    'sold_and_fulfilled_enabled',
    'tap_to_watch',
    'thumbnail_cta',
    'thumbnail_product_card',
    'ui_full_cta',
    'wait_for_vast_attrs',
]);
/**
 * Keys whose values are integers.
 *
 * @example
 * <fw-storyblock paginate_by="3"></fw-storyblock>
 * `paginate_by` value is set to number 3.
 */
const INT_VALUE_KEYS = [
    'cookies_days',
    'max_videos',
    'overlay_play_button_size',
    'paginate_by',
    'start_time',
    'title_size',
];
/**
 * Validate single widget param.
 *
 * @param param Param set by publishers from widget attributes.
 * @returns Boolean if param is allowed.
 */
export const allowWidgetParam = (param) => ALLOWED_KEYS.has(param) ||
    ALLOWED_PREFIXES.some((prefix) => param.startsWith(prefix));
/**
 * @param widgetParams Params set by publishers from widget attributes.
 * @returns The sanitized widget params.
 */
export const sanitizeWidgetParams = (widgetParams, options = {}) => {
    return (Object.entries(widgetParams)
        // Filter out skipped from options
        .filter(([key]) => !(options.skip && options.skip.includes(key)))
        // Filter allowed params
        .filter(([key]) => allowWidgetParam(key))
        // Convert numeric strings to integers
        .map(([key, value]) => INT_VALUE_KEYS.includes(key)
        ? [key, parseInt(value ?? '0', 10)]
        : [key, value])
        // Convert falsy strings to booleans
        // We consider an empty string boolean param to be true,
        // but normally "" is falsy, so we make a separate check here
        .map(([key, value]) => BOOL_VALUE_KEYS.has(key)
        ? [key, value === '' || !isFalsy(value)]
        : [key, value])
        // Keep only nonempty params, unless key in exceptions
        .filter(([key, value]) => EMPTY_VALUE_KEYS.includes(key) || value !== '')
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));
};
