import { lens } from '@dhmk/zustand-lens';
import { createPlayerState } from './state';
export const PLAYER_SLICE_KEY = 'player';
/**
 * Creates a slice of the global state for the player.
 */
export function createPlayerSlice() {
    return {
        [PLAYER_SLICE_KEY]: lens((set) => createPlayerState(set)),
    };
}
