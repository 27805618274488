/** A list of events supported by the custom event lib. */
export var EventType;
(function (EventType) {
    EventType["FW_READY"] = "fw:ready";
    EventType["FW_ERROR"] = "fw:error";
    EventType["FW_NAVIGATE"] = "fw:navigate";
    EventType["FW_EMBEDFEED_ERROR"] = "fw:embed-feed:error";
    EventType["FW_EMBEDFEED_RENDER"] = "fw:embed-feed:render";
    EventType["FW_EMBEDFEED_CONTENTREADY"] = "fw:embed-feed:content-ready";
    EventType["FW_EMBEDFEED_IMPRESSION"] = "fw:embed-feed:impression";
    EventType["FW_EMBEDFEED_THUMBNAILLOAD"] = "fw:embed-feed:thumbnail-load";
    EventType["FW_EMBEDFEED_THUMBNAILCLICK"] = "fw:embed-feed:thumbnail-click";
    EventType["FW_EMBEDFEED_THUMBNAILHOVER"] = "fw:embed-feed:thumbnail-hover";
    EventType["FW_EMBEDFEED_CTA_IMPRESSION"] = "fw:embed-feed:cta-impression";
    EventType["FW_EMBEDFEED_CTA_CLICK"] = "fw:embed-feed:cta-click";
    EventType["FW_HEROUNIT_RENDER"] = "fw:herounit:render";
    EventType["FW_HEROUNIT_IMPRESSION"] = "fw:herounit:impression";
    EventType["FW_STORYBLOCK_RENDER"] = "fw:storyblock:render";
    EventType["FW_STORYBLOCK_IMPRESSION"] = "fw:storyblock:impression";
    EventType["FW_ADD_TO_CALENDAR_RENDER"] = "fw:add-to-calendar:render";
    EventType["FW_ADD_TO_CALENDAR_IMPRESSION"] = "fw:add-to-calendar:impression";
    EventType["FW_SHARE_RENDER"] = "fw:share:render";
    EventType["FW_SHARE_IMPRESSION"] = "fw:share:impression";
    EventType["FW_BUTTONWRAPPER_RENDER"] = "fw:buttonwrapper:render";
    EventType["FW_BUTTONWRAPPER_IMPRESSION"] = "fw:buttonwrapper:impression";
    EventType["FW_BUTTONWRAPPER_CLICK"] = "fw:buttonwrapper:click";
    EventType["FW_PLAYER_RENDER"] = "fw:player:render";
    EventType["FW_PLAYER_IMPRESSION"] = "fw:player:impression";
    EventType["FW_PROGRAMMATICPLAYER_RENDER"] = "fw:programmatic-player:render";
    EventType["FW_PROGRAMMATICPLAYER_IMPRESSION"] = "fw:programmatic-player:impression";
    EventType["FW_SHOWROOM_SCHEDULE_RENDER"] = "fw:showroom-schedule:render";
    EventType["FW_SHOWROOM_SCHEDULE_IMPRESSION"] = "fw:showroom-schedule:impression";
    EventType["FW_LIVE_HELPER_READY"] = "fw:live-helper:ready";
    EventType["FW_LIVE_HELPER_EXTERNAL_START_CHAT"] = "fw:live-helper:external-start-chat";
    EventType["FW_LIVE_HELPER_EXTERNAL_SHOW_WIDGET"] = "fw:live-helper:external-show-widget";
    EventType["FW_LIVE_HELPER_EXTERNAL_START_CALL"] = "fw:live-helper:external-start-call";
    EventType["FW_LIVE_HELPER_EXTERNAL_START_AVA"] = "fw:live-helper:external-start-ava";
    EventType["FW_VIDEO_CLICK_VIDEO"] = "fw:video:click";
    EventType["FW_VIDEO_IMPRESSION"] = "fw:video:impression";
    EventType["FW_VIDEO_START"] = "fw:video:start";
    EventType["FW_VIDEO_FIRSTQUARTILE"] = "fw:video:first-quartile";
    EventType["FW_VIDEO_MIDPOINT"] = "fw:video:midpoint";
    EventType["FW_VIDEO_THIRDQUARTILE"] = "fw:video:third-quartile";
    EventType["FW_VIDEO_COMPLETE"] = "fw:video:complete";
    EventType["FW_VIDEO_SESSION_ENDED"] = "fw:video:session";
    EventType["FW_VIDEO_CLICK_CTA"] = "fw:video:click-cta";
    EventType["FW_VIDEO_CLICK_SHARE"] = "fw:video:click-share";
    EventType["FW_VIDEO_SHARE"] = "fw:video:share";
    EventType["FW_VIDEO_CLICK_EMAIL_EMBED"] = "fw:video:click-email-embed";
    EventType["FW_VIDEO_CLICK_QR_CODE"] = "fw:video:click-qr-code";
    EventType["FW_VIDEO_FULLSCREEN"] = "fw:video:fullscreen";
    EventType["FW_VIDEO_MUTE"] = "fw:video:mute";
    EventType["FW_VIDEO_UNMUTE"] = "fw:video:unmute";
    EventType["FW_VIDEO_INTERACTABLE_ENGAGED_VIEW"] = "fw:video:interactable-engaged-view";
    EventType["FW_VIDEO_INTERACTIVE_START"] = "fw:video:interactive-start";
    EventType["FW_VIDEO_AD_START"] = "fw:video:ad-start";
    EventType["FW_VIDEO_AD_SKIP"] = "fw:video:ad-skip";
    EventType["FW_VIDEO_AD_END"] = "fw:video:ad-end";
    EventType["FW_VIDEO_AD_START_ERROR"] = "fw:video:ad-start-error";
    EventType["FW_PLAYER_NEXT"] = "fw:player:next";
    EventType["FW_PLAYER_PREV"] = "fw:player:prev";
    EventType["FW_PLAYER_QUIT"] = "fw:player:quit";
    EventType["FW_PLAYER_TRANSITION"] = "fw:player:transition";
    EventType["FW_PLAYER_NAVIGATE"] = "fw:player:navigate";
    EventType["FW_PLAYER_CONTENT_CHANGE"] = "fw:player:content-change";
    EventType["FW_PLAYER_CTA_IMPRESSION"] = "fw:player:cta:impression";
    EventType["FW_SHOPPING_READY"] = "fw:shopping:ready";
    EventType["FW_SHOPPING_CONFIGURE"] = "fw:shopping:configure";
    EventType["FW_SHOPPING_HYDRATECART"] = "fw:shopping:hydrate-cart";
    EventType["FW_SHOPPING_HYDRATEPRODUCTS"] = "fw:shopping:hydrate-products";
    EventType["FW_SHOPPING_UPDATEQUANTITY"] = "fw:shopping:cart-updated";
    EventType["FW_SHOPPING_REQUESTCHECKOUT"] = "fw:shopping:request-checkout";
    EventType["FW_SHOPPING_PRODUCT_CARD_IMPRESSION"] = "fw:shopping:product-card-impression";
    EventType["FW_SHOPPING_PRODUCT_CARD_CLICKED"] = "fw:shopping:product-card-clicked";
    EventType["FW_SHOPPING_PRODUCT_CLICKED"] = "fw:shopping:product-clicked";
    EventType["FW_SHOPPING_PDP_PRIMARY_CTA_CLICKED"] = "fw:shopping:pdp-primary-cta-clicked";
    EventType["FW_SHOPPING_PINNED_PRODUCT_CLICKED"] = "fw:shopping:pinned-product-clicked";
    EventType["FW_LIVESTREAM_START_WATCH"] = "fw:livestream:start-watch";
    EventType["FW_LIVESTREAM_USER_JOIN"] = "fw:livestream:join";
    EventType["FW_LIVESTREAM_QUIT"] = "fw:livestream:quit";
    EventType["FW_LIVESTREAM_SEND_CHAT"] = "fw:livestream:send-chat";
    EventType["FW_LIVESTREAM_SEND_HEART"] = "fw:livestream:send-heart";
    EventType["FW_LIVESTREAM_MODERATOR_LINK_CLICK"] = "fw:livestream:click-moderator-link";
    EventType["FW_LIVESTREAM_INTERACTION_SUBMIT_ANSWER"] = "fw:video:submit-answer";
    EventType["FW_LIVESTREAM_INTERACTION_SUBMIT_CHOICE"] = "fw:video:submit-choice";
    EventType["FW_ADS_UNFILLED_AD"] = "fw:ads:unfilled_ad";
    EventType["FW_ADS_FILLED_AD"] = "fw:ads:filled_ad";
})(EventType || (EventType = {}));
