/**
 * @fileoverview
 * Types used by storage.
 */
export var StorageConsentLevel;
(function (StorageConsentLevel) {
    StorageConsentLevel["NOOP"] = "noop";
    StorageConsentLevel["ESSENTIAL"] = "essential";
    StorageConsentLevel["FUNCTIONAL"] = "functional";
    StorageConsentLevel["PERFORMANCE"] = "performance";
    StorageConsentLevel["TARGETING"] = "targeting";
})(StorageConsentLevel || (StorageConsentLevel = {}));
/**
 * Strictly necessary cookies are classified as cookies that must be present for the website
 * to provide the basic functions of the website. They are essential to be able to access
 * features of the website and could include signing in, adding items to a cart, or e-billing.
 * They are typically essential first-party session cookies, but not all first-party cookies
 * are strictly necessary cookies. They allow for a user to navigate back and forth between
 * pages without losing their previous actions from the same session. Strictly necessary
 * cookies are the only cookies that all cookie laws allow to be exempt from requiring
 * user consent. Since these cookies are necessary for the functionality of a website,
 * website owners do not have to get consent from the user to place strictly necessary
 * cookies on their devices.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-strictly-necessary-cookies/
 */
export var StorageKeyEssentialEnum;
(function (StorageKeyEssentialEnum) {
    // Microsites
    StorageKeyEssentialEnum["fw_tv_cookie_consent_accepted"] = "acceptedCookieConsentOnFirework";
    StorageKeyEssentialEnum["fw_tv_ssrchnnl"] = "fw_tv_ssrchnnl";
    // Widgets & Player
    StorageKeyEssentialEnum["fw_ads_spcfgpl"] = "fw_ads_spcfgpl";
    StorageKeyEssentialEnum["fw_ads_spcfgchnl"] = "fw_ads_spcfgchnl";
    StorageKeyEssentialEnum["fw_ads_spcfgoauthcid"] = "fw_ads_spcfgoauthcid";
    StorageKeyEssentialEnum["fw_consent"] = "fw_consent";
    StorageKeyEssentialEnum["fw_ef_pinned_collapsed"] = "fw_ef_pinned_collapsed"; /** Embed feed carousel in pinned mode collapsed state */
    StorageKeyEssentialEnum["fw_gs"] = "fw_gs";
    StorageKeyEssentialEnum["fw_ply_amnmze"] = "fw_ply_amnmze";
    StorageKeyEssentialEnum["fw_ply_cemail"] = "fw_ply_cemail";
    StorageKeyEssentialEnum["fw_ply_coords"] = "fw_ply_coords";
    StorageKeyEssentialEnum["fw_ply_dedupe_ad"] = "fw_ply_dedupe_ad";
    StorageKeyEssentialEnum["fw_ply_ots"] = "fw_ply_ots";
    StorageKeyEssentialEnum["fw_ply_pipexit"] = "fw_ply_pipexit";
    StorageKeyEssentialEnum["fw_ply_scrll"] = "fw_ply_scrll";
    StorageKeyEssentialEnum["fw_ply_sft_pip"] = "fw_ply_sft_pip";
    StorageKeyEssentialEnum["fw_ply_vast"] = "fw_ply_vast";
    StorageKeyEssentialEnum["fw_ply_vclsa"] = "fw_ply_vclsa";
    StorageKeyEssentialEnum["fw_ply_vctc"] = "fw_ply_vctc";
    StorageKeyEssentialEnum["fw_ply_vctct"] = "fw_ply_vctct";
    StorageKeyEssentialEnum["fw_tab_id"] = "fw_tab_id";
    StorageKeyEssentialEnum["fw_vid_int"] = "fw_vid_int";
    // OTO project
    StorageKeyEssentialEnum["fw_otos"] = "fw_otos";
    StorageKeyEssentialEnum["fw_otocall"] = "fw_otocall";
    StorageKeyEssentialEnum["fw_otoconv"] = "fw_otoconv";
    StorageKeyEssentialEnum["fw_chat_channel"] = "fw_chat_channel";
})(StorageKeyEssentialEnum || (StorageKeyEssentialEnum = {}));
/**
 * Functionality cookies allow websites to remember the user’s site preferences and
 * choices they make on the site including username, region, and language. This allows
 * the website to provide personalized features like local news stories and weather if
 * you share your location. They are anonymous and don’t track browsing activity across
 * other websites. Functionality cookies can include first party, third party, persistent
 * or session cookies. Similar to strictly necessary cookies, functionality cookies are
 * used to provide services you request. These cookies can remember your preferences to
 * boost the user experience on a website.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-functionality-cookies/
 */
export var StorageKeyFunctionalEnum;
(function (StorageKeyFunctionalEnum) {
    StorageKeyFunctionalEnum["fw_se"] = "fw_se";
    StorageKeyFunctionalEnum["fw_uid"] = "fw_uid";
    StorageKeyFunctionalEnum["fw_chid"] = "fw_chid";
    StorageKeyFunctionalEnum["fw_cchid"] = "fw_cchid";
    StorageKeyFunctionalEnum["fw_dsid"] = "fw_dsid";
    StorageKeyFunctionalEnum["fw_bmid"] = "fw_bmid";
    StorageKeyFunctionalEnum["fw_asidwa"] = "fw_asidwa";
})(StorageKeyFunctionalEnum || (StorageKeyFunctionalEnum = {}));
/**
 * Performance cookies are cookies used specifically for gathering data on how visitors use
 *  a website, which pages of a website are visited most often, or if they get error messages
 * on web pages. These cookies monitor only the performance of the site as the user interacts
 * with it. These cookies don’t collect identifiable information on visitors, which means all
 * the data collected is anonymous and only used to improve the functionality of a website.
 *
 * Website owners can use these cookies to gather statistical data on how their website is
 * performing to make improvements. Some examples of performance cookies are cookies that
 * count page visits, idle time by a user on a page, bounce rates, and load speeds. While
 * performance cookies can be third party cookies, they’re usually first party cookies that
 * are either session or persistent cookies.
 *
 * Generally, these cookies are only used by the website and the data isn’t shared broadly.
 * However, in some cases, third parties can place their cookies on your device through
 * a website you visit to determine the best place for advertisements to be placed on
 * the website based on user behavior.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-performance-cookies/
 */
export var StorageKeyPerformanceEnum;
(function (StorageKeyPerformanceEnum) {
    StorageKeyPerformanceEnum["fw_utm"] = "fw_utm";
    StorageKeyPerformanceEnum["fw_lets"] = "fw_lets";
    StorageKeyPerformanceEnum["fw_vid"] = "fw_vid";
    StorageKeyPerformanceEnum["fw_attr_ref"] = "fw_attr_ref";
    StorageKeyPerformanceEnum["fw_laewats"] = "fw_laewats";
    StorageKeyPerformanceEnum["fw_abt"] = "fw_abt";
    StorageKeyPerformanceEnum["fw_bid"] = "fw_bid";
})(StorageKeyPerformanceEnum || (StorageKeyPerformanceEnum = {}));
/**
 * Targeting and advertising cookies are specifically designed to gather information from
 * you on your device to display advertisements to you based on relevant topics that interest
 * you. Advertisers will place these cookies on a website with the website operator’s
 * permission. The information the cookies gather on you can be shared with other advertisers
 * to measure the performance of their advertisements. Additionally, another purpose of targeting
 * and advertising cookies is to build user profiles from visitors to the website to gather
 * statistics on the performance of the advertisements that can carry across many websites.
 *
 * These cookies are almost always third-party, persistent cookies. This means that the cookies
 * can follow the user as they visit other websites. Sometimes, the site with the advertising
 * cookies does not display the advertisements but it can target users with advertisements
 * elsewhere even after they’ve left the site. Examples of targeting and advertising cookies
 * include social media cookies that are placed on sites to track users around the web to
 * provide ads to them on social media platforms.
 *
 * Source: https://www.cookiepro.com/knowledge/what-are-targeting-advertising-cookies/
 */
export var StorageKeyTargetingEnum;
(function (StorageKeyTargetingEnum) {
    StorageKeyTargetingEnum["placeholder"] = "placeholder";
})(StorageKeyTargetingEnum || (StorageKeyTargetingEnum = {}));
