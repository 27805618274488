import { Fragment, lazy, memo, Suspense } from 'react';
/**
 * Callback to import VideoPlayer that can be reused elsewhere
 * to preload the chunk (onMouseOver before user clicks on thumbnail).
 */
export const importVideoPlayer = () => import(
/* webpackChunkName: "videoplayer" */
'./VideoPlayer');
const Component = lazy(importVideoPlayer);
/**
 * VideoPlayer component. Renders as part of Storybook, Programmatic or Grid (Carousel)
 * Note: Using `memo` to prevent unnecessary re-renders when parent component re-renders.
 */
export const VideoPlayer = memo((props) => (<Suspense fallback={<Fragment />}>
      <Component {...props}/>
    </Suspense>));
