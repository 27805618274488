const MAPPING = {
    live_stream_allow_username_change: 'livestream_allow_username_change', // [CS-7531] BE naming inconsistency
};
/**
 * Apply param aliases. This is useful when we want to rename a param key but
 * the old name could e still in use.
 * @param params
 * @returns
 */
export const aliasWidgetParams = (params, __mapping_di = MAPPING) => {
    return Object.keys(params).reduce((acc, key) => {
        const alias = __mapping_di[key];
        if (alias && !(alias in acc)) {
            acc[alias] = params[key];
            delete acc[key];
        }
        return acc;
    }, params);
};
