import { createPlayerActions } from './actions';
/**
 * Global state for player
 */
export function createPlayerState(set) {
    return {
        actions: createPlayerActions(set),
        playerStates: {},
        playerOpenedWidgetId: undefined,
        isLivestreamJoinEventTrackedByVideoId: {},
    };
}
