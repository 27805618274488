import { PlayerLayout, VideoPlayerOpenReason } from '@core/player/types';
/**
 * Actions for shared player state
 */
export function createPlayerActions(set) {
    return {
        setPlayerState: (widgetId, playerState) => {
            set((state) => {
                const isOpened = [
                    PlayerLayout.FULLSCREEN,
                    PlayerLayout.MINIMIZED,
                ].includes(playerState.layout);
                // Close all players if new entry is fullscreen/minimized
                const playerStates = isOpened
                    ? Object.fromEntries(Object.entries(state.playerStates).map(([widgetId, playerState]) => [
                        widgetId,
                        {
                            ...playerState,
                            openReason: VideoPlayerOpenReason.CLOSE_ME,
                        },
                    ]))
                    : state.playerStates;
                return {
                    playerStates: { ...playerStates, [widgetId]: playerState },
                    playerOpenedWidgetId: isOpened
                        ? playerState.widget_id
                        : playerState.widget_id === state.playerOpenedWidgetId
                            ? undefined
                            : state.playerOpenedWidgetId,
                };
            }, false, 'playerStateSet');
        },
        unsetPlayerState: (widgetId) => {
            set((state) => {
                const { [widgetId]: _, ...playerStates } = state.playerStates || {};
                const playerOpenedWidgetId = widgetId === state.playerOpenedWidgetId &&
                    [PlayerLayout.FULLSCREEN, PlayerLayout.MINIMIZED].includes(playerStates?.[widgetId]?.layout)
                    ? state.playerOpenedWidgetId
                    : undefined;
                return {
                    playerStates,
                    playerOpenedWidgetId,
                };
            }, false, 'playerStateUnset');
        },
        /** Livestream relate global actions */
        setLivestreamJoinEventTracked: (videoId) => {
            set((state) => {
                return {
                    isLivestreamJoinEventTrackedByVideoId: {
                        ...state.isLivestreamJoinEventTrackedByVideoId,
                        [videoId]: true,
                    },
                };
            }, false, 'setLivestreamJoinEventTracked');
        },
        unsetLivestreamJoinEventTracked: (videoId) => {
            set((state) => {
                return {
                    isLivestreamJoinEventTrackedByVideoId: {
                        ...state.isLivestreamJoinEventTrackedByVideoId,
                        [videoId]: false,
                    },
                };
            }, false, 'unsetLivestreamJoinEventTracked');
        },
    };
}
